import React from 'react';
import './TopBar.css';

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="logo">Skill-Savvy</div>
      <div className="account">
        <i className="fas fa-user-circle"></i> {/* FontAwesome for the icon */}
      </div>
    </div>
  );
};

export default TopBar;

