import React from 'react';
import './Menu.css';

const Menu = () => {
  return (
    <div className="menu">
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/products">Products</a></li>
        <li><a href="/help">Help</a></li>
      </ul>
    </div>
  );
};

export default Menu;

