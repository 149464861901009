
import React from 'react';
import './App.css';
import TopBar from './components/TopBar';
import Menu from './components/Menu';
import Banner from './components/Banner';

function App() {
  return (
    <div className="App">
      <Banner />
      <Menu />
      <div className="content">
        {/* Add your content here */}
      </div>
    </div>
  );
}

export default App;
