import React from 'react';
import './Banner.css';
import bannerImage from '../assets/banner.png'; // Ensure the correct file path

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text">
        <h1>Welcome to Skill Savvy</h1>
        <p>Learn and Practice Skills with Fun!</p>
      </div>
    </div>
  );
};

export default Banner;
